/*********************************
 * main.scss
 *********************************/


html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Vendors
@import 'vendors/material-icons';

// Variables
@import 'utility/variables';
@import 'utility/mixins';

// Primary Styling
@import 'theme';
@import 'typography';
// @import 'components';
@import 'site';
