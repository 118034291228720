/*********************************
 * Mixins
 *********************************/

@mixin vertical-align($top: 50%) {
  position: absolute;
  top: $top;
  transform: translateY(-50%);
}

// Full width background image
@mixin full-width-bg($color: #333) {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-color: $color;
  }
}

// Tab/focus outline color
@mixin tab-focus-outline($color: #0e4c91) {
  outline-width: 5px;
  outline-style: auto;
  outline-color: $color;
  outline-color: -webkit-focus-ring-color;
}
