/*********************************
 * CNT Website Homepage
 *********************************/

// Layout
$site-max-width: 1180px;
$site-header-height: 5em;

// Colors
$site-header-bg: #fff;
$site-footer-bg: $color-theme-secondary;

$site-font-light: lighten($color-font-primary, 70%);

// Styling
$site-border-radius: 0.25em;

// Class Templates
%site-nav-links {
  padding: 0.5rem;
  margin: 0 0.5rem;
  width: 100%;
  text-decoration: none;
  white-space: nowrap;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: background-color, border-color, text-decoration, color,
    font-size;
}

%icon {
  vertical-align: middle;
}

body.site {
  // background-color: #fff;
  font-size: 1rem * 1.15;

  a {
    transition: color 0.1s ease-in-out;

    &:hover,
    &:focus {
      // color: $color-theme-primary;
    }
  }
}

.site-container {
  position: relative;
  background-color: #fff;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: auto;
  // grid-template-areas:
  //   "header"
  //   "main"
  //   "footer";
}

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid $color-font-disabled;

  @include full-width-bg($site-header-bg);

  &-inner {
    grid-area: header;

    max-width: $site-max-width;
    margin: 0 auto;
    position: relative;
    padding: 0 $margin;
    height: $site-header-height;
    background-color: $site-header-bg;

    .site-branding {
      display: block;
      float: left;

      height: 100%;

      &:focus > img {
        @include tab-focus-outline($color-font-link);
      }

      img {
        display: block;
        width: $site-header-height * 2.5;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .site-nav {
      $link-border-top: 0.1em;

      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: $margin;
      left: ($site-header-height * 3.5);

      ul {
        display: flex;
        position: relative;

        list-style: none;
        margin: 0;
        padding: 0;

        li {
          &.login {
            display: none;
            margin-left: auto;
            align-self: flex-end;

            a {
              background-color: $color-font-link;
              color: #fff;
              padding: 0.5em 1em;
              border-radius: $site-border-radius;
              border: 1px solid transparent;

              &:hover,
              &:focus {
                background-color: rgba($color-focus, 0.25);
                border-color: $color-font-link;
                border-top-width: 1px;
                color: $color-font-link;
              }
            }
          }

          a {
            @extend %site-nav-links;

            color: $color-font-link;
            // border-top: $link-border-top solid transparent;

            &.active {
              color: $color-font-primary;
              // border-top: $link-border-top solid $color-font-primary;

              &:hover,
              &:focus {
                color: inherit;
                border-color: inherit;
              }
            }

            &:hover,
            &:focus {
              color: $color-sac-orange;
              // border-top: $link-border-top solid $color-sac-orange;
            }

            .icon {
              @extend %icon;

              margin-right: 0.5em;
              font-size: 1.25em;
            }
          }
        }
      }
    }
  }
}

// PRIMARY CONTENT

.site-content {
  grid-area: main;

  max-width: $site-max-width;
  margin: $site-header-height auto 0;
  display: grid;
  position: relative;
  grid-template-columns: 2fr 1fr;

  &-inner {
    padding: $margin;
  }

  &-sidebar {
    position: relative;

    &-inner {
      padding: $margin;
      position: fixed;
      top: $site-header-height;
    }
  }
}

// Login Form

.login-form {
  border: 1px solid $color-font-disabled;
  background-color: lighten($site-footer-bg, 70%);
  padding: $margin/2;
  padding-bottom: $margin;

  label {
    font-size: 1rem;
  }

  button {
    float: right;
    width: $margin * 3;
    margin-left: $margin;
  }

  .forgot-password {
    display: inline-block;
    margin-top: 0.5em;
    font-size: 0.85em;
  }
}

// FOOTER

.site-footer {
  position: relative;
  width: 100%;

  @include full-width-bg($site-footer-bg);

  &::after {
    z-index: 0;
  }

  &-inner {
    grid-area: footer;

    z-index: 1;
    max-width: $site-max-width;
    margin: 0 auto;
    position: relative;
    background-color: $site-footer-bg;
    color: $color-light-gray;
    padding: $margin $margin $margin * 1.5;
    font-size: 0.9rem;

    .copyright-notice {
      float: left;
      color: lighten($site-font-light, 10%);

      a {
        color: $site-font-light;
      }
    }

    .footer-links {
      display: inline-block;
      float: right;

      ul {
        display: flex;

        list-style: none;
        margin: 0;
        padding: 0;

        li {
          a {
            @extend %site-nav-links;

            color: $site-font-light;

            &:hover,
            &active {
              text-decoration: underline;
              transition: text-decoration 0.2s ease-in-out;
            }

            .icon {
              @extend %icon;

              transform: rotate(270deg);
              font-size: 1.25em;
              margin-left: 0.5em;
            }
          }
        }
      }
    }
  }
}
