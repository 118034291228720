/*********************************
 * Theme
 *********************************/

// primary colors 
$color-theme-primary: #4B828C;
$color-theme-primary-dark: darken($color-theme-primary, 30%);
$color-theme-secondary: #2d3338;


// backgrounds
$color-bg-primary: #fff;
$color-bg-secondary: $color-light-gray;
$color-bg-dark: $color-dark-blue;

$primary-sidebar-bg-color: $color-dark-blue;

// borders
$color-border: $color-light-gray;
$border-radius: 0.25rem;
$border-thin: 1px solid $color-border;

// shadows
$drop-shadow: 3px 3px 6px -2px rgba(0, 0, 0, 0.15);



body {
  background-color: $color-theme-secondary;
}

// Top Primary header
.header {
  background-color: $color-bg-primary;
  border-bottom: $border-thin;
}

// Left Primary navigation sidebar
.primary-sidebar {
  background-color: $primary-sidebar-bg-color;

  &-toggle {
    background-color: $primary-sidebar-bg-color;
  }

  .branding {
    padding: $margin/4;
    background-color: $color-bg-primary;
    border-right: $border-thin;
    border-bottom: $border-thin;

    .logo {
      background-image: url('images/sac_logo.png');
    }
  }
}

.primary-nav {
  background-color: $color-bg-dark;
}

.secondary-nav {
  background-color: $color-bg-secondary;
  border-right: $border-thin;
}


// Main workspace area
.workspace-wrapper {
  background-color: $color-bg-primary;
}
