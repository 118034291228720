/*********************************
 * Layout Parameters
 *********************************/


// layout params
$screen-width-min: 1280px;

// breakpoints
$break-workspace-sidebar: 1450px;

// widths
$primary-sidebar-width: 18rem;
$primary-nav-width: 3.5rem;
$workspace-sidebar-width: 1fr;
$workspace-sidebar-min-width: 400px;
$resize-gutter-width: 4px; // Split.JS values must be: fr, px, %, or repeat

// heights
$header-height: 4.5rem;
$workspace-nav-height: 3rem;

// margins and padding
$margin: 2.5rem;
