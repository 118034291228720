/*********************************
 * VARIABLES
 * dependencies: bootstrap;
 *********************************/


// LAYOUT
@import 'layout-params';

// COLORS

$color-dark-blue:         #112435;
$color-dark-gray:         #212121;
$color-light-gray:        #cccccc;

// Brand Colors
$color-sac-orange:        #f15e3e;
$color-sac-blue:          #0e4c91;

// Interactivity colors
$color-focus:             #FEF3CD;

// Font colors
$color-font-primary:      #121212;
$color-font-disabled:     #ccc;
$color-font-link:         $color-sac-blue;


// Accessibility
$tab-focus-outline:       focus-ring-color auto 5px;
