/*********************************
 * Typography
 *********************************/

// Imports

// Opens Sans
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700&display=swap");

// Font families
$font-body: "Open Sans",
Helvetica,
Arial,
sans-serif;

$font-header: $font-body;

html {
  font-size: 14px;
}

body {
  color: $color-font-primary;
  font-family: $font-body;
  font-size: 1rem;
}

a {
  color: $color-font-link;

  &:focus {
    @include tab-focus-outline($color-font-link);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin-bottom: $margin;
}

h1,
h2,
h3,
h4 {
  font-family: $font-header;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: $color-theme-primary;
}

h1 {
  font-size: 1.65rem;
}

h2 {
  font-size: 1.3rem;
}

h3 {
  font-size: 1.15rem;
}

h4 {
  font-size: 1.05rem;
}
